body {
  color: #fff;
  background: linear-gradient(120deg, #ff00964d, #00ccff4d, #00ffcc4d) 0 0 / 300% 300%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  animation: 10s infinite alternate nebula;
  display: flex;
  overflow: hidden;
}

#grid-container {
  grid-template-rows: repeat(15, 1fr);
  grid-template-columns: repeat(20, 1fr);
  gap: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
}

@keyframes nebula {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 100%;
  }
}

.square {
  color: #0000;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Cinzel, serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  transition: font-size .3s, color .3s;
  display: flex;
}

.square:hover, .square.visible {
  color: var(--text-color, #fff);
  font-size: 24px;
}

#container {
  text-align: center;
  color: #1e1e1e;
  max-width: 600px;
  margin: 0 auto;
  font-family: Cinzel, serif;
}

#input-container {
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  display: flex;
}

#input-container input {
  color: #1e1e1e;
  background: #ffffff1a;
  border: 2px solid #ffffff80;
  border-radius: 5px;
  outline: none;
  width: 200px;
  padding: 10px;
  font-family: Cinzel, serif;
  font-size: 18px;
}

#input-container button {
  color: #1e1e1e;
  cursor: pointer;
  background: #fff3;
  border: 2px solid #ffffff80;
  border-radius: 5px;
  padding: 10px 15px;
  font-family: Cinzel, serif;
  font-size: 18px;
  transition: background-color .3s;
}

#input-container button:hover {
  background: #ffffff80;
}

#results {
  color: #1e1e1e;
  margin-top: 20px;
  font-size: 18px;
}

#progress-container {
  text-align: center;
  color: #1e1e1e;
  margin-top: 40px;
  font-family: Cinzel, serif;
  display: none;
}

#progress-container div {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
  display: flex;
}

#progress-container div span {
  background: #fff6;
  border-radius: 5px;
  width: fit-content;
  margin: 5px 0;
  padding: 10px;
  font-size: 18px;
}

#progress-container #progress-text {
  margin-top: 10px;
  font-size: 16px;
}

#container {
  color: #1e1e1e;
  text-align: center;
  padding: 20px;
  font-family: Cinzel, serif;
  transition: opacity 2s;
}

input, button {
  color: #1e1e1e;
  background-color: #0000;
  border: 1px solid #1e1e1e;
  transition: color .3s, border-color .3s;
}
/*# sourceMappingURL=index.2aa63661.css.map */
