body
  margin: 0
  padding: 0
  overflow: hidden
  background: linear-gradient(120deg, rgba(255, 0, 150, 0.3), rgba(0, 204, 255, 0.3), rgba(0, 255, 204, 0.3))
  background-size: 300% 300% // Größer als der Viewport für die Animation
  animation: nebula 10s infinite alternate
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  color: #fff

#grid-container
  display: grid
  grid-template-columns: repeat(20, 1fr)
  grid-template-rows: repeat(15, 1fr)
  gap: 0px
  width: 100vw
  height: 100vh

// Keyframes für die Waber-Animation
@keyframes nebula
  0%
    background-position: 0% 50%
  50%
    background-position: 50% 100%
  100%
    background-position: 100% 50%

.square
  font-family: 'Cinzel', serif
  height: 100%
  width: 100%
  font-size: 18px
  font-weight: bold
  line-height: 24px
  background: transparent
  color: transparent // Zahlen standardmäßig unsichtbar
  display: flex
  justify-content: center
  align-items: center
  border: none
  cursor: pointer
  transition: font-size 0.3s, color 0.3s

  &:hover
    color: var(--text-color, #fff) // Dynamische Textfarbe
    font-size: 24px

  &.visible
    color: var(--text-color, #fff) // Dynamische Textfarbe
    font-size: 24px

#container
  text-align: center
  color: #1e1e1e
  font-family: 'Cinzel', serif
  max-width: 600px
  margin: 0 auto

#input-container
  margin-top: 20px
  display: flex
  justify-content: center
  gap: 10px

  input
    font-family: 'Cinzel', serif
    font-size: 18px
    padding: 10px
    border: 2px solid rgba(255, 255, 255, 0.5)
    background: rgba(255, 255, 255, 0.1)
    color: #1e1e1e
    border-radius: 5px
    outline: none
    width: 200px

  button
    font-family: 'Cinzel', serif
    font-size: 18px
    padding: 10px 15px
    border: 2px solid rgba(255, 255, 255, 0.5)
    background: rgba(255, 255, 255, 0.2)
    color: #1e1e1e
    border-radius: 5px
    cursor: pointer
    transition: background-color 0.3s

    &:hover
      background: rgba(255, 255, 255, 0.5)

#results
  margin-top: 20px
  font-size: 18px
  color: #1e1e1e


#progress-container
  margin-top: 40px
  text-align: center
  color: #1e1e1e
  display: none
  font-family: 'Cinzel', serif

  div
    padding: 0
    display: flex
    justify-content: center
    flex-wrap: wrap
    align-items: center
    gap: 10px

    span
      background: rgba(255, 255, 255, 0.4)
      margin: 5px 0
      padding: 10px
      border-radius: 5px
      font-size: 18px
      width: fit-content

  #progress-text
    font-size: 16px
    margin-top: 10px


#container
  color: #1e1e1e
  text-align: center
  font-family: 'Cinzel', serif
  padding: 20px
  transition: opacity 2s ease

input, button
  color: #1e1e1e
  border: 1px solid #1e1e1e
  background-color: transparent
  transition: color 0.3s, border-color 0.3s
